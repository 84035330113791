import { createStore } from "vuex";

import ApiClient from "@/apis/ApiClient";
import OrganizationsApi from "@/apis/OrganizationsApi";
import QuestionnairesApi from "@/apis/QuestionnairesApi";
import UserTagsApi from "@/apis/UserTagsApi";
import CompetitorsApi from "@/apis/CompetitorsApi";

// MODULES
import auth from "./modules/auth";

export default createStore({
  modules: { auth },
  state: {
    loadingStatus: "LOADING",
    users: [],
    competitors: [],
    organizations: [],
    userTags: [],
    userTagsLimitPerOrg: 10,
    projects: [],
    balancesByProducts: [],
    creditUsages: [],
    productGroups: [],
    userQuestionnaires: [],
    odaseActiveStep: 0
  },
  mutations: {
    setUserOrganization: (state, payload) => {
      state.loadingStatus = "LOADING";
      state.auth.currentUser.organization = payload;
      state.loadingStatus = "IDLE";
    },
    setUserOrganizationID: (state, payload) => {
      state.loadingStatus = "LOADING";
      state.auth.currentUser.organization_id = payload;
      state.loadingStatus = "IDLE";
    },
    setOrganizations: (state, payload) => {
      state.loadingStatus = "LOADING";
      state.organizations = payload;
      state.loadingStatus = "IDLE";
    },
    setProductGroups: (state, payload) => {
      state.loadingStatus = "LOADING";
      state.productGroups = payload;
      state.loadingStatus = "IDLE";
    },
    setBalancesByProducts: (state, payload) => {
      state.loadingStatus = "LOADING";
      state.balancesByProducts = payload;
      state.loadingStatus = "IDLE";
    },
    setCreditUsage: (state, payload) => {
      state.loadingStatus = "LOADING";
      state.creditUsages = payload;
      state.loadingStatus = "IDLE";
    },
    setProjects: (state, payload) => {
      state.loadingStatus = "LOADING";
      state.projects = payload;
      state.loadingStatus = "IDLE";
    },
    addProject: (state, payload) => {
      state.loadingStatus = "LOADING";
      state.projects.push(payload);
      state.loadingStatus = "IDLE";
    },
    updateProject: (state, payload) => {
      state.loadingStatus = "LOADING";
      state.projects = state.projects.map((project) =>
        project.id === payload.id ? payload : project
      );
      state.loadingStatus = "IDLE";
    },
    deleteProject: (state, payload) => {
      state.loadingStatus = "LOADING";
      state.projects = state.projects.filter(
        (project) => project.id !== payload
      );
      state.loadingStatus = "IDLE";
    },
    setUsers: (state, payload) => {
      state.loadingStatus = "LOADING";
      state.users = payload;
      state.loadingStatus = "IDLE";
    },
    updateUser: (state, payload) => {
      state.loadingStatus = "LOADING";
      const user = state.users.find((user) => user.id === payload.id);
      if (user) {
        Object.assign(user, payload);
      }
      state.loadingStatus = "IDLE";
    },
    setCompetitors: (state, payload) => {
      state.loadingStatus = "LOADING";
      state.competitors = payload;
      state.loadingStatus = "IDLE";
    },
    setTag: (state, payload) => {
      state.loadingStatus = "LOADING";
      state.userTags = payload;
      state.loadingStatus = "IDLE";
    },
    updateTag: (state, payload) => {
      const tag = state.userTags.find((tag) => tag.id === payload.id);
      if (tag) {
        Object.assign(tag, payload);
      }
    },
    deleteTag: (state, payload) => {
      state.loadingStatus = "LOADING";
      state.userTags = state.userTags.filter((tag) => tag.id !== payload);
      state.loadingStatus = "IDLE";
    },
    setUserQuestionnaires(state, payload) {
      state.loadingStatus = "LOADING";
      state.userQuestionnaires = payload;
      state.loadingStatus = "IDLE";
    },
    storeS5Answers: (state, payload) => {
      state.s5Answers = payload;
    },
    setOdaseActiveStep: (state, activeStep) => {
      state.odaseActiveStep = activeStep;
    }
  },
  getters: {
    getS5Answers: (state) => state.s5Answers,
  },
  actions: {
    STORE_ODASE_ACTIVE_STEP({ commit }, activeStep) {
      commit("setOdaseActiveStep", activeStep);
    },
    STORE_S5ANSWERS({ commit }, payload) {
      commit("storeS5Answers", payload);
    },
    async RESET_STORE({ commit }) {
      commit("setUserOrganization", null);
      commit("setUserOrganizationID", null);
      commit("setOrganizations", []);
      commit("setProductGroups", []);
      commit("setBalancesByProducts", []);
      commit("setCreditUsage", []);
      commit("setProjects", []);
      commit("setUsers", []);
      commit("setCompetitors", []);
      commit("setTag", []);
      commit("setUserQuestionnaires", []);
    },
    async SET_USER_ORGANIZATION({ commit }, organization_id) {
      const userOrganization = await ApiClient().get(
        `organizations/${organization_id}`
      );
      commit("setUserOrganization", userOrganization.data.data.organization);
    },
    async SET_USER_ORGANIZATION_ID({ commit }, organization_id) {
      commit("setUserOrganizationID", organization_id);
    },
    async SET_ORGANIZATIONS({ commit }) {
      const organizations = await OrganizationsApi().getOrganizations();
      commit("setOrganizations", organizations.data.data);
    },
    async SET_PRODUCT_GROUPS({ commit }) {
      const productGroups = await ApiClient().get("product-groups");
      commit("setProductGroups", productGroups.data.data);
    },
    async SET_BALANCES_BY_PRODUCT_GROUPS({ commit }, organization_id) {
      const balancesByProducts = await ApiClient().get(
        `credits/${organization_id}/balance`
      );
      commit("setBalancesByProducts", balancesByProducts.data.data);
    },
    async SET_CREDIT_USAGES({ commit }, organization_id) {
      const creditUsages = await ApiClient().get(
        `credits/${organization_id}/usage`
      );
      commit("setCreditUsage", creditUsages.data.data);
    },
    async SET_PROJECTS({ commit }, organization_id) {
      const projects = await ApiClient().get(
        `organizations/${organization_id}/projects?include=segments`
      )
      commit("setProjects", projects.data.data);
    },
    async ADD_PROJECT({ commit }, project) {
      commit("addProject", project);
    },
    async UPDATE_PROJECT({ commit }, project) {
      commit("updateProject", project);
    },
    async DELETE_PROJECT({ commit }, project_id) {
      commit("deleteProject", project_id);
    },
    async SET_USERS({ commit }) {
      const users = await ApiClient()
        .get(
          'users?include=roles,organization,tags,projects,assignments,assignments.sendgridWebhookEvents,permissions,reports,sendgridWebhookEvents,assignments.consumerInput.segment'
        )
        .then((res) => {
          return res.data.data
        })
      commit("setUsers", users);
    },
    async UPDATE_USER({ commit }, user) {
      commit("updateUser", user);
    },
    async SET_USER_QUESTIONNAIRES({ commit }, user_id) {
      const userQuestionnaires = await ApiClient().get(
        `questionnaire-by-user/${user_id}`
      );
      commit("setUserQuestionnaires", userQuestionnaires.data);
    },
    async SET_COMPETITORS({ commit }, organization_id) {
      const competitors = await CompetitorsApi().getCompetitors(
          organization_id,
          'characteristics,strengths,weaknesses,productLines'
        )
        .then((res) => {
          return res.data.data
        })
      commit("setCompetitors", competitors);
    },
    async SET_TAG({ commit }, organization_id) {
      const tags = await UserTagsApi()
        .getTags(organization_id)
        .then((res) => {
          return res.data.data.filter(tag => this.state.organizations.find(org => org.id === tag.organization_id)?.parent_id !== null);
        });
      commit("setTag", tags);
    },
    async UPDATE_TAG({ commit }, tag) {
      commit("updateTag", tag);
    },
    async DELETE_TAG({ commit }, tag_id) {
      commit("deleteTag", tag_id);
    },
    // TODO: Update questionnaire status by action instead of calling the backend endpoint again
    //async UPDATE_QUESTIONNAIRE_STATUS({ commit }, payload) {
    // ...
    //commit("updateQuestionnaireStatus", updatedQuestionnaire.data.data);
    //}
  },
});
